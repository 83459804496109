import React, { useState, useEffect } from "react";
import localforage from "localforage";
import { default as CrAlert } from "@cashremit/alert";
import { ContentWrapper, ButtonWrapper } from "./style";

const CookAlertBanner = ({ cookieName }) => {
  const [alertDismissed, setAlertDismissed] = useState(true);
  useEffect(() => {
    localforage
      .getItem(cookieName)
      .then((isDismissed) => isDismissed === null && setAlertDismissed(false))
      .catch(() => setAlertDismissed(false));
  }, [cookieName]);

  const handleButtonClick = () => {
    localforage
      .setItem(cookieName, true)
      .then((result) => setAlertDismissed(result))
      .catch(() => setAlertDismissed(true));
  };

  const closeText = "Close !";

  return alertDismissed ? null : (
    <CrAlert type="warning">
      <ContentWrapper>
        <p>
          By continuing to use our site you agree to our use of cookies to
          support your experience
        </p>
        <ButtonWrapper>
          <a href onClick={() => handleButtonClick()}>
            {closeText}
          </a>
        </ButtonWrapper>
      </ContentWrapper>
    </CrAlert>
  );
};
export default CookAlertBanner;
