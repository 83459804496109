import React from "react";

import { default as CrAlert } from "@cashremit/alert";
import { ContentWrapper, ButtonWrapper, AlertWrapper } from "./style";
import { CloseIcon } from "../../components/SendMoney/data";

const Alert = () => {
  const [alertDismissed, setAlertDismissed] = React.useState(false);
  const buttonText = "More info";
  const buttonHref = "https://www.cashremit.com.au";

  //const closeText = " Close !";

  const handleButtonClick = () => {
    setAlertDismissed(true);
  };

  return alertDismissed ? (
    <></>
  ) : (
    <AlertWrapper>
      <CrAlert type="info">
        <ContentWrapper>
          <p>
            <strong>Until September 30, 2023,</strong> you have the option to
            utilize POLi for your payment transfers. Please note that POLi will
            no longer be supported on our platform beyond this date. However,
            you can still make payments for your transfers through the New PayTo
            or PayID methods. Additionally, we've introduced a Wallet system
            that allows you to fund your wallet and make payments at a later
            time.
          </p>
          <ButtonWrapper>
            <a href={buttonHref} target="_blank" rel="noopener noreferrer">
              {buttonText}
            </a>
            <a href onClick={() => handleButtonClick()}>
              {<CloseIcon />}
            </a>
          </ButtonWrapper>
        </ContentWrapper>
      </CrAlert>
    </AlertWrapper>
  );
};
export default Alert;
