import styled from "styled-components";

export const ContentWrapper = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSize.xs};
    margin-left: ${({ theme }) => theme.space.xs};
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    margin-left: ${({ theme }) => theme.space.md};
    margin-top: 0;
    border: solid 1px #2196f3;
    padding: 0 10px;
    margin-left: 10em;
    border-radius: 6px;
  }
`;
